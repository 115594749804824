

if (window.location.href.indexOf('uzenetek/') > -1) {
    document.getElementById('unread_msg').innerHTML = '';
    localStorage.removeItem('unopened_message');

    const userid_input = document.getElementById('current_user');
    const username_input = document.getElementById('current_username');
    const to_userid_input = document.getElementById('to_user');
    const to_username_input = document.getElementById('to_username');
    var id_counter = 0;
    let lastScrollHeight = $('#messages').prop('scrollHeight');
    let noMoreMessages = false;
    let lastScrollTop = 0;
    let scrollEvery = 0;
    let lastLastMsg = 0;
    let dscrollTop = 0;
    let dscrollLeft = 0;

    let lastSent = null;

    (function () {

        let alreadyLoadedLatestMessages = false;


        let other_user_id = $("#other_user").val();


        let user_id = $("#other_user").val();


        let chatBox = $("#chat_box_" + user_id);

        if (!chatBox.hasClass("chat-opened")) {
            chatBox.addClass("chat-opened").slideDown("fast");

            if (!alreadyLoadedLatestMessages) {
                loadLatestMessages(chatBox, user_id, (response) => {
                    alreadyLoadedLatestMessages = true;
                });
            }

            chatBox.find(".chat-area").animate({ scrollTop: $('#messages').prop('scrollHeight') }, 50, 'swing');
        }

        // on click the btn send the message
        input = document.getElementById('message_input');
        input.addEventListener('keydown', function (e) {
            if (e.keyCode === 13) {
                e.preventDefault();
            }
        })
        input.addEventListener("keyup", function (event) {
            // Number 13 is the "Enter" key on the keyboard
            if (event.keyCode === 13) {
                // Cancel the default action, if needed
                event.preventDefault();
                // Trigger the button element with a click
                document.getElementById("message_send").click();
            }
        });


        $("#message_send").on("click", function (e) {
            var msg = document.getElementById('message_input').value;
            document.getElementById('message_input').value = "";
            send($(this).attr('data-to-user'), msg, null);
        });





        document.getElementsByClassName("chat-area")[0].addEventListener("scroll", chatScroll);

        if (document.getElementById('app_me_id').value != '') {
            // here listen for pusher events
            setTimeout(() => {
                let current_user_id = $("#app_me_id").val();
                window.Echo.private(`chat-message.${current_user_id}`)
                    .listen('.message.sent', (e) => {
                        updateLastRead(e.message.id);
                        displayReceiverMessage(e.message);
                    });
            }, 200);
            setTimeout(() => {
                let current_user_id = $("#app_me_id").val();
                window.Echo.private(`chat-message-from.${current_user_id}`)
                    .listen('.message.sent', (e) => {
                        updateLastRead(e.message.id);
                        if (lastSent != null) {
                            if (lastSent.to_user != e.message.to_user.id && lastSent.from_user != e.message.from_user.id && lastSent.content != e.message.content) {
                                console.log(lastSent);
                                console.log(e.message);
                                displaySenderMessage(e.message);
                            }
                        }
                        if (lastSent == null)
                            displaySenderMessage(e.message);
                    });
            }, 200);
        }
    })();

    let scrolled = 0;
    function chatScroll() {
        if (noMoreMessages) {
            return;
        }
        let st = $(".chat-area").scrollTop();


        if (st < lastScrollTop) {
            scrollEvery += 1;

            if (lastLastMsg != $("#last_loaded_msg_id").val() && st < 10) {
                lastLastMsg = $("#last_loaded_msg_id").val();
                disableScroll();
                fetchOldMessages($(".to_user_id").val(), $("#last_loaded_msg_id").val(), (response) => {
                    noMoreMessages = response.no_more_messages;

                    if (noMoreMessages) {
                        let chatArea = $(this).parents(".chat-opened").find(".chat-area");
                        chatArea.prepend(noMoreTemplate());

                        setTimeout(() => {
                            chatArea.find(".no-more-messages").remove();
                        }, 1500);

                    }
                });
                enableScroll();
            }
        }

        lastScrollTop = st;
    }

    function chatScrollDisable() {
        if (lastScrollHeight != $('#messages').prop('scrollHeight')) {
            dscrollTop += ($('#messages').prop('scrollHeight') - lastScrollHeight)
            lastScrollHeight = $('#messages').prop('scrollHeight');
        }
        document.getElementsByClassName("chat-area")[0].scrollTo(dscrollLeft, dscrollTop);
    }



    function disableScroll() {
        let dscrollTop = $(".chat-area").scrollTop();
        let dscrollLeft = $(".chat-area").scrollLeft();
        // Get the current page scroll position

        document.getElementsByClassName("chat-area")[0].removeEventListener("scroll", chatScroll);
        document.getElementsByClassName("chat-area")[0].addEventListener("scroll", chatScrollDisable);

    }

    function enableScroll() {
        document.getElementsByClassName("chat-area")[0].removeEventListener("scroll", chatScrollDisable);
        document.getElementsByClassName("chat-area")[0].addEventListener("scroll", chatScroll);
    }


    /**
     * send message function
     *
     * @param to_user
     * @param message
     */
    function send(to_user, message, file) {

        let chat_box = $("#chat_box_" + to_user);
        let chat_area = chat_box.find(".chat-area");

        var msg = {
            'to_user': { 'id': to_user },
            'from_user': { 'id': document.getElementById('app_me_id').value },
            'content': message,
            'file': file,
            'id': id_counter
        };
        lastSent = msg;

        let formData = new FormData();
        formData.append("to_user", to_user);
        formData.append("_token", $("meta[name='csrf-token']").attr("content"));
        formData.append("message", message);
        formData.append("image", file);

        $.ajax({
            url: window.base_url + "/send",
            data: formData,
            method: "POST",
            dataType: "json",
            processData: false,
            contentType: false,
            beforeSend: function () {
                if (msg.content != '') {
                    displaySenderMessage(msg);
                    //chat_area.animate({ scrollTop: $('#messages').prop('scrollHeight') }, 50, 'swing');
                }
            },
            success: function (response) {
                //displaySenderMessage(response.message);
                id_counter += 1;
            },
            complete: function () {
            }
        }).fail(function (jqXHR, ajaxOptions, thrownError) {
            if (document.getElementById(`sent_unchecked_message-${id_counter}`) != null) {
                document.getElementById(`sent_unchecked_message-${id_counter}`).addEventListener('click', () => { resendMsg(msg) });
                document.getElementById(`sent_unchecked_message-${id_counter}`).classList.add('msg_not_sent');
            }
            id_counter += 1;
        });
    }

    function resendMsg(msg) {
        document.getElementById(`sent_unchecked_message-${msg.id}`).remove();
        send(msg.to_user.id, msg.content, null);
    }

    function loaderHtml() {
        return `<i class="glyphicon glyphicon-refresh loader"></i>`;
    }


    /**
     * display message on the sender side
     *
     * @param message
     */
    function displaySenderMessage(message) {
        //check if message is from instant pic
        let chat_box = $("#chat_box_" + message.to_user.id);
        let chat_area = chat_box.find(".chat-area");

        if (message.id == null) {
            if ($("#current_user").val() == message.from_user.id) {
                let messageLine = getMessageSenderTemplate(message);
                if (message.image == 'instant') {
                    messageLine = getMessageInstantTemplate(message);
                }
                $("#chat_box_" + message.to_user.id).find(".chat-area").append(messageLine);
                chat_area.animate({ scrollTop: $('#messages').prop('scrollHeight') + 100 }, 50, 'swing');
            }
        } else {
            if ($("#current_user").val() == message.from_user.id) {
                let messageLine = getMessageSenderTemplateWithId(message);
                if (message.image == 'instant') {
                    messageLine = getMessageInstantTemplate(message);
                }
                $("#chat_box_" + message.to_user.id).find(".chat-area").append(messageLine);
                chat_area.animate({ scrollTop: $('#messages').prop('scrollHeight') + 100 }, 50, 'swing');
            }
        }
    }

    /**
     * display message on the receiver side
     *
     * @param message
     */
    function displayReceiverMessage(message) {
        if ($("#current_user").val() == message.to_user.id) {

            // for the receiver user check if the chat box is already opened otherwise open it


            let chatBox = $("#chat_box_" + message.from_user.id);

            if (!chatBox.hasClass("chat-opened")) {

                chatBox.addClass("chat-opened").slideDown("fast");

                loadLatestMessages(chatBox, message.from_user.id);

                chatBox.find(".chat-area").animate({ scrollTop: $('#messages').prop('scrollHeight') + 100 }, 50, 'swing');
            } else {
                if ($("#message-line-" + message.id).length == 0) {
                    let messageLine = getMessageReceiverTemplate(message);
                    if (message.image == 'instant') {
                        messageLine = getMessageInstantTemplate(message);
                    }

                    // append the message for the receiver user
                    $("#chat_box_" + message.from_user.id).find(".chat-area").append(messageLine);
                    chatBox.find(".chat-area").animate({ scrollTop: $('#messages').prop('scrollHeight') + 100 }, 50, 'swing');
                }
            }
        }
    }

    function updateLastRead(id) {
        $.ajax({
            url: window.base_url + "/update_latest_recieved",
            data: {
                _token: $("meta[name='csrf-token']").attr("content"),
                msg_id: id
            },
            method: "patch",
            dataType: "json",
            complete: function () {
            }
        });
    }

    /**
    * loadLatestMessages
    *
    */
    function loadLatestMessages(container, user_id, callback = null) {
        let chat_area = container.find(".chat-area");
        chat_area.html("");

        $.ajax({
            url: window.base_url + "/load-latest-messages",
            data: { user_id: user_id, _token: $("meta[name='csrf-token']").attr("content") },
            method: "GET",
            dataType: "json",
            beforeSend: function () {
                if (chat_area.find(".loader").length == 0) {
                    chat_area.html(loaderHtml());
                }
            },
            success: function (response) {
                if (response.state == 1) {
                    document.getElementById("last_loaded_msg_id").value = response.last_msg;
                    response.messages.map(function (val, index) {
                        $(val).appendTo(chat_area);
                    });

                    if (callback) {
                        callback(response);
                    }
                }
            },
            complete: function (response) {
                chat_area.find(".loader").remove();
            }
        });

    }

    /**
     * fetchOldMessages
     *
     * this function load the old messages if scroll up triggerd
     */
    function fetchOldMessages(to_user, old_message_id, callback = null) {
        let chat_box = $("#chat_box_" + to_user);
        let chat_area = chat_box.find(".chat-area");

        $.ajax({
            url: window.base_url + "/fetch-old-messages",
            data: { to_user: to_user, old_message_id: old_message_id, _token: $("meta[name='csrf-token']").attr("content") },
            method: "GET",
            dataType: "json",
            beforeSend: function () {
                if (chat_area.find(".loader").length == 0) {
                    chat_area.prepend(loaderHtml());
                }
            },
            success: function (response) {
                document.getElementById("last_loaded_msg_id").value = response.last_msg;
                response.messages.map(function (val, index) {
                    $(chat_area).prepend(val);
                });

                if (callback) {
                    callback(response);
                }
            },
            complete: function () {
                chat_area.find(".loader").remove();
                if ($(".chat-area").scrollTop() == 0 && !noMoreMessages) {
                    disableScroll();
                    fetchOldMessages($(".to_user_id").val(), $("#last_loaded_msg_id").val(), (response) => {
                        noMoreMessages = response.no_more_messages;

                        if (noMoreMessages) {
                            let chatArea = $(this).parents(".chat-opened").find(".chat-area");
                            chatArea.prepend(noMoreTemplate());

                            setTimeout(() => {
                                chatArea.find(".no-more-messages").remove();
                            }, 1500);

                        }
                    });
                    enableScroll();
                }
            }
        });
    }

    /**
     * getMessageSenderTemplate
     *
     * this is the message template for the sender
     *
     * @param message
     * @returns {string}
     */
    function getMessageSenderTemplate(message) {
        const body = getMessageBody(message);
        to_return = '';
        to_return += `
     <div class="my_message message">
     <div class="thumb"> `;
        if (ImageExist(`/storage/profile_pictures/${message.from_user.id}/thumbnail.jpg`)) {
            to_return += `<img
            src=" /storage/profile_pictures/${message.from_user.id}/thumbnail.jpg">`;
        } else if (ImageExist(`/storage/profile_pictures/${message.from_user.id}/picture_one.jpg`)) {
            to_return += `<img
            src=" /storage/profile_pictures/${message.from_user.id}/picture_one.jpg">`;
        } else {
            to_return += `<img src="/no-img.jpg">`;
        }

        to_return += `</div>
     <div class="text">
         ${body}
     </div>
 </div>`;

        return to_return;
    }

    function getMessageSenderTemplateWithId(message) {
        const body = getMessageBody(message);
        to_return = '';
        to_return += `
     <div class="my_message message" id="sent_unchecked_message-${message.id}">
     <div class="thumb"> `;

        if (ImageExist(`/storage/profile_pictures/${message.from_user.id}/thumbnail.jpg`)) {
            to_return += `<img
        src=" /storage/profile_pictures/${message.from_user.id}/thumbnail.jpg">`;
        } else if (ImageExist(`/storage/profile_pictures/${message.from_user.id}/picture_one.jpg`)) {
            to_return += `<img
        src=" /storage/profile_pictures/${message.from_user.id}/picture_one.jpg">`;
        } else {
            to_return += `<img src="/no-img.jpg">`;
        }
        to_return += `</div>
                        <div class="text">
                            ${body}
                        </div>
                    </div>`;

        return to_return;
    }

    function getMessageInstantTemplate(message) {
        const body = getMessageBody(message);
        to_return = '';
        if (body.includes("Instant Kép: Mostantól a kép elérhető ")) {
            to_return += `
            <div class="instant_message">
            <div class="text">
             <a href="#" id="instant_accessable_msg">${body}</a>
            </div>
            </div>`;
        } else {
            to_return += `
            <div class="instant_message">
            <div class="text">
             ${body}
            </div>
            </div>`;
        }

        return to_return;
    }

    function ImageExist(url) {
        var http = new XMLHttpRequest();
        http.open('HEAD', url, false);
        http.send();

        return http.status >= 200 && http.status < 400;
    }

    /**
     * getMessageReceiverTemplate
     *
     * this is the message template for the receiver
     *
     * @param message
     * @returns {string}
                    */
    function getMessageReceiverTemplate(message) {
        const body = getMessageBody(message);

        to_return = '';
        to_return += `
                    <div class="user_message message">
                        <div class="thumb"> `;

        if (ImageExist(`/storage/profile_pictures/${message.from_user.id}/thumbnail.jpg`)) {
            to_return += `<img
                                src=" /storage/profile_pictures/${message.from_user.id}/thumbnail.jpg?t=${Date.now()}">`;
        } else if (ImageExist(`/storage/profile_pictures/${message.from_user.id}/picture_one.jpg`)) {
            to_return += `<img
                                src=" /storage/profile_pictures/${message.from_user.id}/picture_one.jpg?t=${Date.now()}">`;
        } else {
            to_return += `<img src="/no-img.jpg">`;
        }

        /*
        if (message.from_user.profile_picture_one != null)
            to_return += `<img src="/storage/profile_pictures/` + message.from_user.id + "/" + message.from_user.profile_picture_one + `">`;
        else if (message.from_user.profile_picture_one == null && message.from_user.profile_picture_two != null)
            to_return += `<img src="/storage/profile_pictures/` + message.from_user.id + "/" + message.from_user.profile_picture_two + `">`;
        else if ((message.from_user.profile_picture_one == null && message.from_user.profile_picture_two == null) && message.from_user.profile_picture_three != null)
            to_return += `<img src="/storage/profile_pictures/` + message.from_user.id + "/" + me_pic_three.value + `">`;
        else
            to_return += `<img src="/no-img.jpg">`;
*/
        to_return += `</div>
                        <div class="text">
                            ${body}
                        </div>
                    </div>`;

        return to_return;
    }

    function getMessageBody(message) {
        let content = '';

        if (message.content) {
            content = message.content;
        } else if (message.image_url) {
            content = '<div style="width: 100%;"><img class="img-responsive" src="' + message.image_url + '" /></div>';
        }

        return content;
    }

    function noMoreTemplate() {
        return `<div class="no-more-messages text-center">Nincs több üzenet</div>`;
    }

    function getMessages() {
        shouldScroll = messages.scrollTop + messages.clientHeight === messages.scrollHeight;
        // After getting your messages.
        if (!shouldScroll) {
            scrollToBottom();
        }
    }

    $(document).ready(function () {
        $('#messages').animate({
            scrollTop: $("#messages")[0].scrollHeight
        });
    });

}

