require('./bootstrap');
require('../../node_modules/slick-carousel');

$(document).ready(function() {
    //BETA VERSION
    $('.side-menu .navbar-brand, .navbar-main .navbar-brand').append('<span class="beta-text">beta</span>');
  
    //VEGYES CLASS
    if (window.location.href.indexOf('sajat-progi') > -1 || window.location.href.indexOf('progi-letrehozasa') > -1) {
      $('body').addClass('mixed');
    }
     //CREATE PROGI CLASS
     if (window.location.href.indexOf('program') > -1) {
      $('body').addClass('mixed');
    }
     //MOVIE CLASS
     if (window.location.href.indexOf('mozi') > -1) {
      $('body').addClass('movie');
    }
    //FOOD CLASS
    if (window.location.href.indexOf('kaja') > -1) {
      $('body').addClass('food');
    }
    //login CLASS
    if (window.location.href.indexOf('bejelentkezes') > -1) {
      $('body').addClass('login');
    }
    //register CLASS
    if (window.location.href.indexOf('regisztracio') > -1) {
      $('body').addClass('register');
    }
    if (window.location.href.indexOf('aszf') > -1) {
      $('body').addClass('register');
    }
    if (window.location.href.indexOf('adatvedelmi') > -1) {
      $('body').addClass('register');
    }
    //register CLASS
    if (window.location.href.indexOf('social') > -1) {
      $('body').addClass('register');
    }
    //password-reset CLASS
    if (window.location.href.indexOf('jelszo/visszaallitas') > -1) {
      $('body').addClass('password-reset');
    }
    //password-reset CLASS
    if (window.location.href.indexOf('megerosites') > -1) {
      $('body').addClass('verify');
    }
    //password-reset CLASS
    if (window.location.href.indexOf('email/megerosites') > -1) {
      $('body').addClass('verify');
    }
    //profile-pictures CLASS
    if (window.location.href.indexOf('profilkep-feltoltes') > -1) {
      $('body').addClass('setup-profile-images');
    }
    //profile-pictures CLASS
    if (window.location.href.indexOf('profilkep-frissites') > -1) {
      $('body').addClass('update-profile-images');
    }
    //setup CLASS
    if (window.location.href.indexOf('profil-reszletek') > -1) {
      $('body').addClass('setup-profile-complete');
    }
     //setup CLASS
     if (window.location.href.indexOf('uzenetek/') > -1) {
      $('body').addClass('chat-room');
    }
    //FILTER BTN
    if (screen.width <= 960) {
      $('#filter_btn, #collapseFilter').prependTo('.body-main');
    }
    if ( $("body.chat-room .section").is("#CINEMA") ) {
      $("body").addClass('movie');
    }
    if ( $("body.chat-room .section").is("#RESTAURANT") ) {
      $("body").addClass('food');
    }
    if ( $("body.chat-room .section").is("#MIXED") ) {
      $("body").addClass('mixed');
    }
    //UNREAD MSG
    $('#unread_msg').appendTo('i.fa-comment');
    
    //EMOJI PICKER
    $('.emoji-picker').removeClass('light');
    $('.emoji-picker').addClass('dark');
    
});

import axios from 'axios';

require('./chat');

//GRID LIST VIEW
$("#view-to-list").click(function(){
  $('.member-card_container').removeClass('col-6 col-lg-4');
  $('.member-card_container').addClass('col-12 col-lg-6');
  $('#view-to-grid').removeClass('d-none');
  $('#view-to-list').addClass('d-none');
  $('#data-wrapper').removeClass('view-grid').addClass('view-list');
});

$("#view-to-grid").click(function(){
  $('.member-card_container').removeClass('col-12 col-lg-6');
  $('.member-card_container').addClass('col-6 col-lg-4');
  $('#view-to-list').removeClass('d-none');
  $('#view-to-grid').addClass('d-none');
  $('#data-wrapper').removeClass('view-list').addClass('view-grid');
});

//CHAT INPUT AUTOSIZE
var div = document.querySelector('#ta-frame');
var ta =  document.querySelector('#message_input');
if(ta != null)
ta.addEventListener('keyup', autosize);

function autosize() {
  setTimeout(function() {
    ta. style.cssText = 'height:0px';
    var height = Math.min(20 * 4, ta.scrollHeight);
    div.style.cssText = 'height:' + height + 'px';
    ta. style.cssText = 'height:' + height + 'px';
  },0);
}

$("#message_send").click(function(){
  ta.style.cssText = 'height:auto';
  div.style.cssText = 'height:auto';
});

if ($('#unread_msg').is(':empty')) {
  $('.nav-item.messages').removeClass('has-unread');
} else {
  $('.nav-item.messages').addClass('has-unread');
}

//CHAT EMOJI
$('#emoji-trigger').click(function(){
  $('.emoji-picker').removeClass('light');
  $('.emoji-picker').addClass('dark');
});


//create progi
$(document).ready( function() {
  var now = new Date();
  var month = (now.getMonth() + 1);               
  var day = now.getDate();
  if (month < 10) 
      month = "0" + month;
  if (day < 10) 
      day = "0" + day;
  var today = now.getFullYear() + '-' + month + '-' + day;
  $('#ProgiDate').val(today);
});



  
